<template>
  <footer id="about" class="footer">
    <section class="columns">
      <div class="column is-3">
        <div class="brand-wrapper">
          <img src="@/icons/mss.png" id="vue-seo-logo" alt="media_solusi_sukses" width="102" height="55">
          <h2 class="title adlam-display-regular has-text-light">Media Solusi Sukses</h2>
        </div>
      </div>
      <div class="column">
        <div class="columns">
          <div class="column">
            <div class="brand-wrapper">
              <h4 class="has-text-weight-bold">
                <img
                  src="https://static.vecteezy.com/system/resources/previews/037/825/594/non_2x/ai-generated-3d-rendering-of-a-modern-office-building-png.png"
                  alt="web" width="20" height="20">
                Office:
              </h4>
              <p>Perum. Bumi Karawang Residence, Blok G12
                No. 7-9, Desa Cengkong, Kecamatan
                Purwasari, Kabupaten Karawang, 41373</p>
            </div>
            <div class="brand-wrapper">
              <h4 class="has-text-weight-bold">
                <img
                  src="https://images.vexels.com/media/users/3/205068/isolated/preview/0f0deb0930df99c4cbc12e364c7b33a2-telephone-blue-icon.png"
                  alt="phone" width="20" height="20">
                Phone:
              </h4>
              <p class="has-text-light">021-397 00 444</p>
            </div>
            <div class="brand-wrapper">
              <h4 class="has-text-weight-bold">
                <img
                  src="https://static.vecteezy.com/system/resources/thumbnails/016/716/480/small_2x/whatsapp-icon-free-png.png"
                  alt="phone" width="20" height="20">
                Whatsapp:
              </h4>
              <a class="has-text-info"
                href="https://wa.me/62812100665?text=Hallo Marketing MSS, Saya tertarik dengan produk anda!">0812-100-665
                (NOC)
              </a>
              <br>
              <a class="has-text-info"
                href="https://wa.me/6281229285291?text=Hallo Marketing MSS, Saya tertarik dengan produk anda!">0812-2928-5291
                (Marketing 1)
              </a>
              <br>
              <a class="has-text-info"
                href="https://wa.me/6285600299019?text=Hallo Marketing MSS, Saya tertarik dengan produk anda!">0856-0029-9019
                (Marketing 2)
              </a>
            </div>

            <div class="brand-wrapper">
              <h4 class="has-text-weight-bold">
                <img src="https://cdn-icons-png.flaticon.com/512/8743/8743996.png" alt="web" width="20" height="20">
                Website:
              </h4>
              <a class="has-text-info" href="https://mediasolusisukses.co.id">mediasolusisukses.co.id</a>
            </div>
          </div>
          <div class="column">
            <div class="brand-wrapper">
              <h4 class="has-text-weight-bold">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/1280px-Gmail_icon_%282020%29.svg.png"
                  alt="mail" width="20" height="20">
                Email:
              </h4>
              <a class="has-text-info"
                href="mailto:admin.office@mediasolusisukses.co.id?subject=Request%20Solution&body=Hallo%20admin%2C%0D%0A%0D%0Abagaimana%20cara%20menjadi%20partner%20di%20PT.%20Media%20Solusi%20Sukses">admin.office@mediasolusisukses.co.id</a>
            </div>
            <div class="brand-wrapper">
              <h4 class="has-text-weight-bold">
                <img src="https://pngate.com/wp-content/uploads/2023/09/Instagram-logo-roundet-3d.png" alt="instagram"
                  width="20" height="20"> Instagram:
              </h4>
              <a class="has-text-info"
                href="https://www.instagram.com/mediasolusisukses.pt?igsh=dmlwY2p1endtcGtn">mediasolusisukses.pt</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>
