<script setup>
import { useMetaRoute } from '@/composables';

useMetaRoute();
</script>

<template>
  <div>
    <h2>About PT Media Solusi Sukses</h2>
  </div>
</template>
