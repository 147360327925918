export type Feature = Readonly<{
  description: string;
  fade?: string;
}>;

export const FEATURES: Feature[] = [
  {
    description: 'Shafa.net sebagai merek dagang resmi PT. Media Solusi Sukses.',
    fade: 'fade-right'
  },
  {
    description: 'Beroperasi sejak tahun 2017, dimulai dari usaha RT/RW Net.',
    fade: 'fade-right'
  },
  {
    description: 'Penyedia layanan internet (ISP)',
    fade: 'fade-right'
  },
  {
    description: 'Melayani Jawa Barat, Jawa Timur, dan Jawa Tengah, dengan rencana ekspansi ke seluruh Pulau Jawa.',
    fade: 'fade-right'
  },
  {
    description: 'Dukungan teknis 24 jam untuk memberikan solusi kebutuhan pelanggan',
    fade: 'fade-right'
  }
];
