<script setup>
import { useMetaRoute } from '@/composables';

useMetaRoute();
</script>

<template>
  <div class="view-wrapper">
    <section class="hero is-dark bg-image is-fullheight trans-smooth" data-aos="fade-up">
      <div class="hero-body has-text-centerd is-flex-desktop-only">
        <div class="container-fluid">
          <div class="columns">
            <section class="column" data-aos="fade-right">
              <h1 class="p-2 sz-100 adlam-display-regular is-size-1-mobile has-text-left">Media Solusi Sukses</h1>
            </section>
          </div>
          <h4 class="has-text-left">Perusahaan yang bergerak dibidang penyedia layanan internet atau yang sering dikenal
            sebagai ISP dan saat ini sudah menjangkau sebagian wilyah Indonesia. Berdiri sejak tahun 2017 berawal dari
            usaha RT/RW Net dan akhirnya berkembang menjadi sebuah perusahaan.</h4>
          <br>
          <h4>Pelayanan serta kepuasan pelanggan adalah tujuan dari kami dengan merekrut tenaga operator dan NOC yang
            melayani dan memberikan solusi selama 24 jam sesuai kebutuhan pelanggan.</h4>
        </div>
      </div>
    </section>

    <section id="noc" :class="['container dashboard-content is-justify-content-center my-4', themeCls]">
      <section class="column" data-aos="fade-right">
        <h1 class="sz-100 poppins-extralight is-size-1-mobile has-text-centered">NOC</h1>
      </section>
      <ul data-aos="fade-up">
        <li style="--accent-color:#0B374D">
          <div class="title">01</div>
          <div class="descr">Network Monitoring</div>
        </li>
        <li style="--accent-color:#0B374D">
          <div class="title">02</div>
          <div class="descr">Application establishment investigating Refreshing</div>
        </li>
        <li style="--accent-color:#0B374D">
          <div class="title">03</div>
          <div class="descr">Incident Response</div>
        </li>
        <li style="--accent-color:#0B374D">
          <div class="title">04</div>
          <div class="descr">Threat Analysis</div>
        </li>
        <li style="--accent-color:#0B374D">
          <div class="title">05</div>
          <div class="descr">Antivirus filtering and remediation</div>
        </li>
        <li style="--accent-color:#0B374D">
          <div class="title">06</div>
          <div class="descr">Firewall and IPS Checking and administration</div>
        </li>
        <li style="--accent-color:#0B374D">
          <div class="title">07</div>
          <div class="descr">Patch Management</div>
        </li>
        <li style="--accent-color:#0B374D">
          <div class="title">08</div>
          <div class="descr">Backup and Capacity</div>
        </li>
        <li style="--accent-color:#0B374D">
          <div class="title">09</div>
          <div class="descr">Communication & Administration (Email, Voice, & Administration)</div>
        </li>
        <li style="--accent-color:#0B374D">
          <div class="title">10</div>
          <div class="descr">Performance, Quality and advancement announcing</div>
        </li>
      </ul>
    </section>

    <section id="visi" :class="['container dashboard-content is-justify-content-center my-4', themeCls]"
      data-aos="fade-left">
      <div class="columns">
        <div class="column">
          <h3 class="sz-100 poppins-extralight is-size-1-mobile">Visi Perusahaan</h3>
        </div>
        <div class="column">
          <div class="column my-5">
            <section data-aos="fade-up">
              <p class="title">Layanan Jasa dan Produk terbaik</p>
              <div class="content">
                <p>Memberikan pelanggan solusi layanan jasa dan produk terbaik dengan mengutamakan kepuasan pelanggan
                  yang menjadikan PT. MEDIA SOLUSI SUKSES sebagai pilihan utama dalam layanan jasa jaringan Internet</p>
              </div>
            </section>
          </div>
          <div class="column my-5">
            <section data-aos="fade-up">
              <p class="title">Kepuasan Pelanggan</p>
              <div class="content">
                <p>Mengutamakan kepuasan pelanggan dalam setiap layanan yang diberikan.</p>
              </div>
            </section>
          </div>
          <div class="column my-5">
            <section data-aos="fade-up">
              <p class="title">Internet Berkualitas</p>
              <div class="content">
                <p>Menjadi pemimpin terpercaya dalam penyediaan layanan jaringan internet berkualitas</p>
              </div>
            </section>
          </div>
        </div>
      </div>
      <hr>
    </section>

    <section id="misi" :class="['container dashboard-content is-justify-content-center p-0', themeCls]">
      <div class="columns">
        <div class="column is-hidden-desktop">
          <h3 class="sz-100 poppins-extralight is-size-1-mobile">Misi Perusahaan</h3>
        </div>
        <div class="column">
          <div class="column my-5">
            <section data-aos="fade-up">
              <p class="title">Meningkatkan Kualitas Pelayanan</p>
              <div class="content">
                <p>Memberikan layanan terbaik yang didukung oleh solusi dan inovasi dalam jaringan telekomunikasi untuk
                  memenuhi dan meningkatkan kebutuhan pelanggan.</p>
              </div>
            </section>
          </div>
          <div class="column my-5">
            <section data-aos="fade-up">
              <p class="title">Pengembangan Infrastruktur Nasional</p>
              <div class="content">
                <p>Membangun dan memperluas infrastruktur jaringan di berbagai kota di Indonesia dengan memanfaatkan
                  teknologi terbaru untuk memberikan konektivitas terbaik.</p>
              </div>
            </section>
          </div>
          <div class="column my-5">
            <section data-aos="fade-up">
              <p class="title">Pengembangan Sumber Daya Manusia</p>
              <div class="content">
                <p>Secara konsisten mengembangkan kompetensi dan kualitas sumber daya manusia untuk mendukung
                  keberhasilan perusahaan dan kepuasan pelanggan.</p>
              </div>
            </section>
          </div>
        </div>
        <div class="column is-hidden-mobile">
          <h3 class="sz-100 poppins-extralight is-size-1-mobile">Misi Perusahaan</h3>
        </div>
      </div>
      <hr>
    </section>

    <section id="coverage" :class="['container dashboard-content is-justify-content-center p-0 py-3', themeCls]">
      <h3 class="sz-50 poppins-extralight is-size-1-mobile">Coverage Area</h3>
      <div class="columns has-text-centered">
        <div :class="[`column`]" data-aos="fade-up" data-aos-delay="500">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28202114.434687987!2d103.54625115927462!3d-2.3643166270093827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2c4c07d7496404b7%3A0xe37b4de71badf485!2sIndonesia!5e1!3m2!1sen!2sid!4v1737445145893!5m2!1sen!2sid"
            width="100%" height="450" style="border:0;" allowfullscreen="true" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
          <br>
          <div class="card p-3">
            <h6 class="has-text-weight-bold is-family-monospace">“Membangun jaringan yang handal dan merata untuk
              menghubungkan seluruh Indonesia,
              menghadirkan
              konektivitas tanpa batas di setiap sudut negeri.”</h6>
          </div>
        </div>
      </div>
    </section>

    <section id="products" class="container dashboard-content is-justify-content-center p-0 py-3">
      <h3 class="sz-50 poppins-extralight is-size-1-mobile">Products</h3>
      <div class="columns is-multiline has-text-centered is-fullheight">
        <div class="column is-one-quarter">
          <div class="card card-400 radius-custom-2">
            <div class="card-image">
              <figure class="image is-4by3">
                <img src="@/assets/products/1.jpg" alt="Placeholder image" />
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Koneksi Broadband</p>
                </div>
              </div>

              <div class="content">
                Sesuai dengan kebutuhan pelanggan (Personal dan Perusahaan)
              </div>
            </div>
          </div>
        </div>
        <div class="column is-one-quarter">
          <div class="card card-400 radius-custom-2">
            <div class="card-image">
              <figure class="image is-4by3">
                <img src="@/assets/products/2.jpg" alt="Placeholder image" />
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Koneksi Internet Dedicated</p>
                </div>
              </div>

              <div class="content">
                Mengutamakan kestabilan untuk personal dan perusahaan.
              </div>
            </div>
          </div>
        </div>

        <div class="column is-one-quarter">
          <div class="card card-400 radius-custom-2">
            <div class="card-image">
              <figure class="image is-4by3">
                <img src="@/assets/products/3.jpg" alt="Placeholder image" />
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Tersedia VPN</p>
                </div>
              </div>

              <div class="content">Virtual Private Network (VPN Data)</div>
            </div>
          </div>
        </div>
        <div class="column is-one-quarter">
          <div class="card card-400 radius-custom-2">
            <div class="card-image">
              <figure class="image is-4by3">
                <img src="@/assets/products/4.jpg" alt="Placeholder image" />
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Tersedia ERP</p>
                </div>
              </div>

              <div class="content">Enterprise Resources Planning (ERP)</div>
            </div>
          </div>
        </div>

        <div class="column is-one-quarter">
          <div class="card card-400 radius-custom-2">
            <div class="card-image">
              <figure class="image is-4by3">
                <img src="@/assets/products/5.jpg" alt="Placeholder image" />
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Hosting</p>
                </div>
              </div>

              <div class="content">Web dan Server Hosting</div>
            </div>
          </div>
        </div>
        <div class="column is-one-quarter">
          <div class="card card-400 radius-custom-2">
            <div class="card-image">
              <figure class="image is-4by3">
                <img src="@/assets/products/6.jpg" alt="Placeholder image" />
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Co-location</p>
                </div>
              </div>

              <div class="content">Server Co-location</div>
            </div>
          </div>
        </div>
        <div class="column is-one-quarter">
          <div class="card card-400 radius-custom-2">
            <div class="card-image">
              <figure class="image is-4by3">
                <img src="@/assets/products/7.jpg" alt="Placeholder image" />
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Tersedia LAN</p>
                </div>
              </div>

              <div class="content">
                Koneksi Internet Perusahaan dengan Local Area Network(LAN)
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </section>


    <section id="partners" :class="['container dashboard-content is-justify-content-center p-0 py-3', themeCls]">
      <h3 class="sz-50 poppins-extralight is-size-1-mobile">Partners</h3>
      <div class="columns has-text-centered is-multiline ">
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/2.png" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/17.png" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/18.jpg" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/19.jpg" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/1.jpg" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/3.png" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/4.png" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/5.jpg" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/6.png" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/7.jpg" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/8.png" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/9.png" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/10.jpg" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/12.jpg" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/13.jpg" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/14.jpg" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/15.jpg" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/16.jpg" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/20.jpg" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/21.jpg" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/22.png" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/23.jpg" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/24.png" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/25.png" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/26.jpg" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/27.png" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/28.png" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/29.png" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/30.png" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/31.jpg" alt="mitra1" height="200" width="200">
        </div>
        <div :class="[`column is-one-fifth`]" data-aos="fade-up" data-aos-delay="500">
          <img src="@/assets/partners/32.png" alt="mitra1" height="500" width="500">
        </div>
      </div>
    </section>


    <section :class="['container dashboard-content is-justify-content-center p-3 py-3 mg-small', themeCls]">
      <hr>
      <div class="columns is-1-mobile is-0-tablet is-3-desktop my-2">
        <div :class="[`column card radius-custom has-text-centered mb-4 mx-2`]" data-aos="fade-up" data-aos-delay="500">
          <h4 class="has-text-weight-bold	sz-30">Be Kind</h4>
          <p class="has-text-dark">Do the right thing.</p>
        </div>
        <div class="column card radius-custom has-text-centered mb-4 mx-2" data-aos="fade-down" data-aos-delay="600">
          <h4 class="has-text-weight-bold	sz-30">Be Honest</h4>
          <p class="has-text-dark">Keep your word.</p>
        </div>
        <div class="column card radius-custom has-text-centered mb-4 mx-2" data-aos="fade-up" data-aos-delay="700">
          <h4 class="has-text-weight-bold	sz-30">Be Smart</h4>
          <p class="has-text-dark">Make things better.</p>
        </div>
      </div>
      <hr>
    </section>
  </div>
</template>

<style lang="scss">
.bg-image {
  background-image: url("@/assets/networkgif2.gif");
  background-size: cover;
}

.sz-30 {
  font-size: 30px;
}

.sz-50 {
  font-size: 80px;
}

.sz-80 {
  font-size: 80px;
  color: '#1641ce' !important
}

.sz-100 {
  font-size: 100px;
  color: '#1641ce' !important
}

.radius-custom {
  border-radius: 3rem 0 3rem 0 !important;
  background-color: #edeef2 !important;
  color: #1641ce;
}

.radius-custom-2 {
  border-radius: 0 0 4rem 0 !important;
}

.adlam-display-regular {
  font-family: "ADLaM Display", serif;
  font-weight: 400;
  font-style: normal;
}

.audiowide-regular {
  font-family: "Audiowide", serif;
  font-weight: 400;
  font-style: normal;
}

.card-400 {
  height: 450px;
}

.mt-50 {
  margin-top: 50px !important;
}

.trans-smooth {
  transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  overflow: hidden;
}

.blink {
  animation: blink-animation 1.5s steps(5, start) infinite;
  -webkit-animation: blink-animation 1.5s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes blink-animation {
  to {
    opacity: 1;
  }
}
</style>

<style>
@import url("https://pro.fontawesome.com/releases/v6.0.0-beta1/css/all.css");
@import url("https://fonts.googleapis.com/css2?family=Jura:wght@500;600;900&display=swap");

h1 {
  text-align: center;
}

ul {
  --col-gap: 2rem;
  --barH: 1rem;
  --roleH: 2rem;
  --flapH: 2rem;

  width: min(60rem, 90%);
  margin-inline: auto;

  display: flex;
  flex-wrap: wrap;

  gap: var(--col-gap);
  padding-inline: calc(var(--col-gap) / 2);

  justify-content: center;
  align-items: flex-start;
  list-style: none;
}

ul li {
  width: 10em;
  display: grid;
  grid-template:
    "role"
    "icon"
    "title"
    "descr";
  align-items: flex-start;
  gap: 1rem;
  padding-block-end: calc(var(--flapH) + 1rem);
  text-align: center;
  background: var(--accent-color);
  background-image: linear-gradient(rgba(0, 0, 0, 0.6) var(--roleH),
      rgba(0, 0, 0, 0.4) calc(var(--roleH) + 0.5rem),
      rgba(0, 0, 0, 0) calc(var(--roleH) + 0.5rem + 5rem));
  clip-path: polygon(calc(var(--col-gap) / -2 - 5px) 0,
      calc(100% + var(--col-gap) / 2 + 5px) 0,
      calc(100% + var(--col-gap) / 2 + 5px) calc(100% - var(--flapH)),
      50% 100%,
      calc(var(--col-gap) / -2 - 5px) calc(100% - var(--flapH)));
}

/* bar */
ul li::before {
  content: "";
  grid-area: role;
  height: var(--barH);
  width: calc(100% + var(--col-gap));
  margin-left: calc(var(--col-gap) / -2);
  margin-top: calc(var(--roleH) / 2 - var(--barH) / 2);
  background: grey;
  z-index: -1;
  background-image: linear-gradient(rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.2) 30%,
      rgba(255, 255, 255, 0.1) 40%,
      rgba(0, 0, 0, 0.1) 60%,
      rgba(0, 0, 0, 0.2) 70%,
      rgba(0, 0, 0, 0.4));
}

/* role */
ul li::after {
  content: "";
  grid-area: role;
  background: var(--accent-color);
  background-image: linear-gradient(rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.2) 30%,
      rgba(255, 255, 255, 0.1) 40%,
      rgba(0, 0, 0, 0.1) 60%,
      rgba(0, 0, 0, 0.2) 70%,
      rgba(0, 0, 0, 0.4));
  height: var(--roleH);
}

ul li .icon,
ul li .title,
ul li .descr {
  padding-inline: 1rem;
  color: white;
  text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
}

ul li .icon {
  font-size: 3rem;
}

ul li .title {
  font-size: 2.25rem;
  font-weight: 700;
}

ul li .descr {
  font-size: 0.9rem;
}

.credits {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.credits a {
  color: var(--color);
}
</style>
